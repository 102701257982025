import { BrowserRouter as Router, Routes, Navigate, Route } from "react-router-dom";
import { Layout } from './layout';
import { Home, Login, Reset } from './screens';
import { NotificationProvider } from './utils/notification';

function App() {
  return (
    <Router>
      <NotificationProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/reset" element={<Reset />} />
            {/* <Route path="/register" element={<Register />} /> */}
            {/* <Route path="/confirm" element={<Confirm />} /> */}
          </Routes>
        </Layout>
      </NotificationProvider>
    </Router>
  );
}

export default App;
