import { Form, Formik } from "formik";
import { Input } from "utils/input";

interface EmailFormProps {
  onSubmit: (email: string) => void;
}

export function EmailForm({ onSubmit }: EmailFormProps) {
  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={({ email }) => onSubmit(email)}
    >
      <Form className="space-y-4 mt-6">
        <Input name="email" label="Email" type="email" required />
        <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              id="submitBtn"
              className="
                    w-full
                    flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-blue-600
                    hover:bg-blue-500
                    focus:outline-none focus:border-blue-700 focus:shadow-outline-blue
                    active:bg-blue-700
                    transition
                    duration-150
                    ease-in-out
                  "
            >
              Send reset code
            </button>
          </span>
        </div>
      </Form>
    </Formik>
  );
}