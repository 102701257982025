import Auth from "@aws-amplify/auth";
import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "utils/notification";

interface ResetPasswordInfo {
  verification_code: string;
  email: string;
  password: string;
}

export function useResetPassword() {
  const { error: notifyError, success: notifySuccess } = useNotification();
  const navigate = useNavigate();
  const [params] = useSearchParams({ return_to: "/home" });

  const return_to = params.get("return_to");
  const redirect = new URL(return_to ?? "").toString();

  const resend = React.useCallback(
    (email: string) => {
      Auth.forgotPassword(email).catch(({ message }) => notifyError(message));
    },
    [notifyError]
  );

  const reset = React.useCallback(
    ({ email, verification_code, password }: ResetPasswordInfo) => {
      Auth.forgotPasswordSubmit(email, verification_code, password)
        .then(() => {
          notifySuccess("Password reset successfully");
          navigate({
            pathname: "/login",
            search: `?return_to=${encodeURIComponent(redirect)}`,
          });
        })
        .catch(({ message }) => {
          notifyError(message);
        });
    },
    [notifySuccess, notifyError, navigate, redirect]
  );

  return { reset, resend };
}
