import React from "react";

export function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="min-h-screen bg-white flex">
      <div
        className="
          flex-1 flex flex-col
          justify-center
          py-12
          px-4
          sm:px-6
          lg:flex-none lg:px-20
          xl:px-24
          w-1/3
        "
      >
        <img className="h-20 w-auto mx-auto" src="https://res.cloudinary.com/geerd/image/upload/v1650726364/appdoc/appdoc-logos_transparent_q6hecf.webp" alt="GEERD" />
        {children}
        <p className="text-center text-gray-500 text-xs mt-2">
          &copy;2022 Appdoc. Made with love in Morocco
          <span role="img" aria-label="Flag of Morocco"> 🇲🇦 </span>
        </p>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://res.cloudinary.com/geerd/image/upload/c_scale,w_1600/v1650757187/appdoc/pexels-karolina-grabowska-4386466_psjcra.jpg"
          alt=""
        />
      </div>
    </div>
  );

}
