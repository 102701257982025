import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Input } from "utils/input";

const ChangeUserPasswordSchema = Yup.object().shape({
  password_first: Yup.string().required(),
  password_confirm: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password_first === value;
    }
  ),
});
interface PasswordChangeInfo {
  password_confirm: string;
  password_first: string;
}
interface ChangePasswordFormProps {
  submit: (password_first: PasswordChangeInfo) => void;
}
export function ChangePassword({ submit }: ChangePasswordFormProps) {
  return (
    <div className="mx-auto w-full max-w-sm">
      <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
        Change Password
      </h2>
      <Formik
        initialValues={{ password_first: "", password_confirm: "" }}
        validationSchema={ChangeUserPasswordSchema}
        onSubmit={submit}
      >
        <Form className="space-y-6 mt-6">
          <Input
            name="password_first"
            label="Password"
            type="password"
            required
          />
          <Input
            name="password_confirm"
            label="Retype Password"
            type="password"
            required
          />
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              id="submitBtn"
              className="
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-blue-600
                      hover:bg-blue-500
                      focus:outline-none focus:border-blue-700 focus:shadow-outline-blue
                      active:bg-blue-700
                      transition
                      duration-150
                      ease-in-out
                    "
            >
              Save
            </button>
          </span>
        </Form>
      </Formik>
    </div>
  );
}
