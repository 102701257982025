import { useSearchParams } from "react-router-dom";

export function Home() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  console.log({ token });
  return (
    <div>
      <p>Welcome to your home page</p>
    </div>
  );
};