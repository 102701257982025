import { Link, useSearchParams } from "react-router-dom";
import { useResetPassword } from "./useResetPassword";
import React from "react";
import { ResetForm } from "./ResetForm";
import { EmailForm } from "./EmailForm";

export function Reset() {
  const [email, setEmail] = React.useState<string>();
  const [sent, setSent] = React.useState(false);
  const { reset, resend } = useResetPassword();
  const [params] = useSearchParams({ return_to: "/home" });
  const return_to = params.get("return_to");
  const redirect = new URL(return_to ?? "").toString();

  React.useEffect(() => {
    if (!sent) return;
    const timer = setTimeout(() => setSent(false), 10000);
    return () => clearTimeout(timer);
  }, [sent]);

  const resendCode = React.useCallback(() => {
    if (!email) return;
    resend(email);
    setSent(true);
  }, [resend, email]);

  const resetPassword = React.useCallback((args: { password: string, verification_code: string }) => {
    if (!email) return;
    reset({ email, ...args });
  }, [reset, email]);

  const submitEmail = React.useCallback((email: string) => {
    setEmail(email);
    resend(email);
  }, [resend]);


  return (
    <div className="mx-auto w-full max-w-sm">
      <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
        Password reset
      </h2>
      {email
        ? <ResetForm resendCode={resendCode} sent={sent} onSubmit={resetPassword} />
        : <EmailForm onSubmit={submitEmail} />
      }
      <div className="mt-6 flex items-center justify-between">
        <div className="text-sm leading-5">
          <Link
            to={{
              pathname: "/login",
              search: `?return_to=${encodeURIComponent(redirect)}`,
            }}
            className="
                      font-medium
                      text-blue-600
                      hover:text-blue-500
                      focus:outline-none focus:underline
                      transition
                      ease-in-out
                      duration-150
                    "
          >
            Login
          </Link>
        </div>
        {/* <div className="text-sm leading-5">
          <Link
            to="/register"
            className="
                      font-medium
                      text-blue-600
                      hover:text-blue-500
                      focus:outline-none focus:underline
                      transition
                      ease-in-out
                      duration-150
                    "
          >
            Register
          </Link>
        </div> */}
      </div>
    </div>
  );
}
