import Auth from "@aws-amplify/auth";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "utils/notification";

interface LoginInfo {
  email: string;
  password: string;
}

export function useLoginUser(setuser: any, setmode: any) {
  const notification = useNotification();
  const [params] = useSearchParams({ return_to: "/home" });
  const return_to = params.get("return_to");

  const login = React.useCallback(
    ({ email, password }: LoginInfo) => {
      Auth.signIn(email, password)
        .then((user) => {
          // notification.success(`Welcome back ${given_name} ${family_name}`);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            setuser(user);
            setmode(user.challengeName);
          } else {
            // other situations
            Auth.currentSession()
              .then((session) => {
                const token = session.getRefreshToken().getToken();
                const redirect = new URL(return_to ?? "");
                redirect.searchParams.append("gcal_token", token);
                redirect.searchParams.append("gcal_user", email);
                window.location.href = redirect.href;
              })
              .catch(({ message }) => {
                notification.error(message);
              });
          }
        })
        .catch(({ message }) => {
          notification.error(message);
        });
    },
    [notification, return_to, setmode, setuser]
  );

  return login;
}
