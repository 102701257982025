import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { useField } from "formik";

/* build class names from boolean exprisions */
export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface InputProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
}

export function Input({ name, label, ...props }: InputProps) {
  const [field, meta] = useField(name);
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={name}
          {...field}
          {...props}
          className={classNames(
            meta.touched
              ? meta.error
                ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                : "border-green-300 text-green-900 placeholder-green-300 focus:ring-green-500 focus:border-green-500"
              : "focus:ring-blue-500 focus:border-blue-500 shadow-sm border-gray-300",
            "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          )}
        />

        {meta.touched && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {meta.error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : field.value ? (
              <CheckCircleIcon
                className="h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            ) : (
              <div />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
