import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Amplify from "@aws-amplify/core";
import { COGNITO } from "./config";

// Configure Cognito via Amplify
Amplify.configure(COGNITO);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
